import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectionStrategy,
  ViewEncapsulation
} from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import 'moment-duration-format';
import * as _ from 'lodash';

type VarientView = 'Graph' | 'List';
@Component({
  selector: 'app-variants-list',
  templateUrl: './variants-list.component.html',
  styleUrls: ['./variants-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VariantsListComponent implements OnChanges {
  @ViewChild(CdkVirtualScrollViewport, { static: false })
  cdkVirtualScrollViewport: CdkVirtualScrollViewport;
  // @HostListener('window:resize', ['$event'])
  @Input() variantsList: Array<any> = [];
  @Input() selectedVariantsList: Array<any> = [];
  @Input() startEndId: any;
  @Input() selectedView: any;
  @Input() sortBy: any;
  @Input() isVariantsLoading = true;
  @Input() isMineProcessLoading = true;
  @Input() isLaunchLoading = false;
  @Input() showSlider = false;
  @Input() showLaunch = false;
  @Input() errorMsg = '';

  @Output() variantsSelected = new EventEmitter<any>();
  @Output() launchMineProcess = new EventEmitter();
  @Output() applyVariantsFilter = new EventEmitter<any>();

  public variantCheckedUnchecked = false;
  public percentageOfCases = 0.0;
  public percentageOfVariantsSelected = 0;
  public variantsString = [];
  public variantsSlider = {
    min: 1,
    max: 1,
    value: 1
  };
  reloadGraph = true;

  ngOnChanges(changes) {
    const { variantsList } = changes;
    if (
      variantsList &&
      variantsList.currentValue &&
      variantsList.currentValue.length
    ) {
      if (!this.startEndId || !this.startEndId.length) {
        // this.startEndId = [this.variantsList[0].variant_id, this.variantsList[0].variant_id];
        this.startEndId = [1, 1];
      }
      this.applySorting();
    }
  }

  resetAndApplySorting() {
    this.variantsString = [];
    this.selectedVariantsList = [];
    this.startEndId = [1, 1];
    this.applySorting();
  }

  applySorting() {
    let variantsList = [];
    this.variantsString = [];
    this.variantsList.map(
      obj => (obj.throughput_time = parseFloat(obj.throughput_time))
    );
    if (this.sortBy === 'MOST_VARIANTS') {
      variantsList = _.orderBy(this.variantsList, ['case_count'], ['desc']);
    }
    if (this.sortBy === 'LEAST_VARIANTS') {
      variantsList = _.orderBy(this.variantsList, ['case_count'], ['asc']);
    }
    if (this.sortBy === 'MOST_TIME') {
      variantsList = _.orderBy(
        this.variantsList,
        ['throughput_time'],
        ['desc']
      );
    }
    if (this.sortBy === 'LEAST_TIME') {
      variantsList = _.orderBy(this.variantsList, ['throughput_time'], ['asc']);
    }
    this.reloadGraph = false;
    this.variantsList = [...variantsList];
    this.variantsList.map(obj => (obj.checked = false));
    if (this.variantsList.length) {
      this.variantsList[0].checked = true;
      this.variantsString = [this.variantsList[0].variant_id];
    }
    // const variantIds = this.variantsString ? this.variantsString : [];
    // this.variantsSelected.next({variantIds, selectedView: this.selectedView, startEndId: this.startEndId, sortBy: this.sortBy});
    this.setVariantsIntialPosition();
    setTimeout(() => {
      this.reloadGraph = true;
    }, 200);
  }

  onResize(event) {
    this.cdkVirtualScrollViewport.checkViewportSize();
  }

  setVariantsIntialPosition() {
    this.variantsString = [];
    this.variantsSlider.max = this.variantsList.length;
    if (this.selectedVariantsList.length) {
      this.percentageOfCases = 0;
      this.variantsList.map(variant => {
        if (this.selectedVariantsList.includes(variant.variant_id)) {
          this.percentageOfCases =
            this.percentageOfCases + variant.percent_cases_covered;
          variant.checked = true;
          this.variantsString.push(variant.variant_id);
        }
      });
      this.variantsSlider.value = this.variantsString.length;
      if (this.percentageOfCases > 100) {
        this.percentageOfCases = 100;
      }
    } else {
      this.variantsList[0].checked = true;
      this.percentageOfCases = this.variantsList[0].percent_cases_covered;
      this.variantsString.push(this.variantsList[0].variant_id);
    }

    this.calculatePercentageOfVariants();
    this.variantsSelected.next({
      variantIds: this.variantsString,
      selectedView: this.selectedView,
      startEndId: this.startEndId,
      sortBy: this.sortBy
    });
  }

  calculatePercentageOfVariants(
    selectedVariantsSize = this.variantsString.length
  ) {
    this.percentageOfVariantsSelected = Math.ceil(
      (selectedVariantsSize / this.variantsList.length) * 100
    );
  }

  updateVariantsBySlider(sliderValue, dragEnd = false) {
    this.variantCheckedUnchecked = false;
    const variantsList = [...this.variantsList];

    this.variantsList.reduce((accumulator, variant) => {
      variant.checked = false;
    }, 0);

    this.percentageOfCases = variantsList
      .splice(0, sliderValue)
      .reduce((accumulator, variant, index) => {
        this.variantsList[index].checked = true;
        if (dragEnd) {
          this.variantsString.push(variant.variant_id);
        }
        return accumulator + variant.percent_cases_covered;
      }, 0.0);
    if (this.percentageOfCases > 100) {
      this.percentageOfCases = 100;
    }
  }

  onInputChange(e) {
    this.calculatePercentageOfVariants(e.value);
    this.updateVariantsBySlider(e.value);
  }

  onSliderDragEnd(e) {
    this.variantsString = [];
    this.updateVariantsBySlider(e.value, true);
    this.validateAndLoadLayout();
  }

  onSelectVariant(selecedVariant, e) {
    this.variantCheckedUnchecked = true;
    selecedVariant.checked = e.checked;
    if (e.checked) {
      this.variantsString.push(selecedVariant.variant_id);
      this.percentageOfCases =
        this.percentageOfCases + selecedVariant.percent_cases_covered;
    } else {
      this.percentageOfCases =
        this.percentageOfCases - selecedVariant.percent_cases_covered;
      // tslint:disable-next-line: variable-name
      this.variantsString = this.variantsString.filter(variant_id => {
        return variant_id !== selecedVariant.variant_id;
      });
    }
    // Removes the duplicate fromArray
    this.variantsString = [...new Set(this.variantsString)];
    if (this.percentageOfCases > 100) {
      this.percentageOfCases = 100;
    }
    this.variantsSlider.value = this.variantsString.length;
    this.calculatePercentageOfVariants();
    this.validateAndLoadLayout();
    if (!this.variantsString.length) {
      setTimeout(() => {
        this.setVariantsIntialPosition();
      }, 50);
      return;
    }
  }

  toggleView(view: VarientView) {
    this.selectedView = view;
    if (view === 'List') {
      this.variantsString = [];
      this.selectedVariantsList = [];
      this.variantsList.map(obj => {
        if (obj.checked) {
          this.selectedVariantsList.push(obj.variant_id);
        }
        obj.checked = false;
      });
      this.setVariantsIntialPosition();
    }
  }

  onBrushUpdate(variants: any) {
    const { selectedVariants, startEndId } = variants;
    this.percentageOfCases = 0.0;
    this.startEndId = [...startEndId];
    this.variantsString = selectedVariants.map(variant => {
      this.percentageOfCases =
        this.percentageOfCases + variant.percent_cases_covered;
      return variant.variant_id;
    });
    if (this.percentageOfCases > 100) {
      this.percentageOfCases = 100;
    }
    this.calculatePercentageOfVariants();
    this.validateAndLoadLayout();
  }

  onApplyFilter(event) {
    this.applyVariantsFilter.emit({ filter: true });
  }

  validateAndLoadLayout() {
    const variantIds = this.variantsString ? this.variantsString : [];
    this.variantsSelected.next({
      variantIds,
      selectedView: this.selectedView,
      startEndId: this.startEndId,
      sortBy: this.sortBy
    });
  }

  onLaunchMineProcess() {
    this.launchMineProcess.next(this.variantsString);
  }
}
