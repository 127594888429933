export const Environment = {
                	  production: true,
                	  appVersion: require('../../package.json').version + '-prod',
                	  analysisAPIRoot: '//elb-preprod.enterpriseintellect.cloud',
                	  iEAPIRoot: '//elb-preprod.enterpriseintellect.cloud',
                	  OIAPIRoot: '//elb-preprod.enterpriseintellect.cloud',
                	  dataCollectionAPIRoot: 'elb-preprod.enterpriseintellect.cloud:442/api/dc-v1',
                	  revOperationAPIRoot: 'elb-preprod.enterpriseintellect.cloud/api/revops',
                	  utmAPIRootWithoutHyphen: 'elb-preprod.enterpriseintellect.cloud:444/api/utm-v1/',
                	  utmAPIRoot: 'elb-preprod.enterpriseintellect.cloud:444/api/utm-v1',
                	  taskMiningAPIRoot: '-be1.opxce.com/api/tmv1',
                	  IIAPIRoot: '//elb-preprod.enterpriseintellect.cloud',
                	  revOpsRoot: '//elb-preprod.enterpriseintellect.cloud/api/revops/',
                	  revenupRoot: '//elb-preprod.enterpriseintellect.cloud:446/api/revenup/',
                	  genAI: '//elb-preprod.enterpriseintellect.cloud:446/api/revenup/intel/'
                	};